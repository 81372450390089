import type { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/Button/Button';
import { routes } from '@/constants';
import { useIsMenuItemActive } from '@/hooks';

import { useGameHref } from './MenuItem.hooks';
import type { MenuItemProps } from './MenuItem.types';

import styles from './MenuItem.module.scss';

export const MenuItem: FC<MenuItemProps> = ({ id, menuItem, classes }) => {
  const { t } = useTranslation();

  const { href, text, Icon } = menuItem;

  const { pathname, asPath } = useRouter();

  let isActive = useIsMenuItemActive(href);
  const isHomeHref = href === routes.home;
  const isHomePathname = pathname === routes.home;

  if (isActive && isHomeHref && !isHomePathname) {
    isActive = false;
  }

  const gameHref = useGameHref(typeof href === 'object' ? { pathname: asPath, ...href } : href);

  return (
    <Button
      as="link"
      variant="text"
      skew="right"
      fontSize="sm"
      fontWeight="bold"
      color="red"
      href={gameHref}
      className={cn(styles.root, classes.item, { [cn(styles.active, classes.activeItem)]: isActive })}
    >
      {Icon && <Icon className={styles.icon} />}
      <span className={cn(styles.itemText)}>{t(id, text).toUpperCase()}</span>
    </Button>
  );
};
