import type { FC } from 'react';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  getLinkIsActive,
  inlineQueries,
  selectIsErrorPage,
  selectUserIsAuthenticated,
  useAccessCookie,
  useBonusBalanceCount,
  useLogos,
  useQueryPopupOpen,
} from '@mwl/core-lib';
import { NoSSR } from '@mwl/ui';

import { Balance } from '@/components/Balance/Balance';
import { BonusButton } from '@/components/BonusButton/BonusButton';
import type { ButtonAsLink } from '@/components/Button/Button';
import { Button } from '@/components/Button/Button';
import { ButtonLuckyWheel } from '@/components/ButtonLuckyWheel/ButtonLuckyWheel';
import { ColoredButton } from '@/components/ColoredButton/ColoredButton';
import { Download } from '@/components/Download/Download';
import { Image } from '@/components/Image/Image';
import { NotificationWrapper } from '@/components/NotificationWrapper/NotificationWrapper';
import { PopupRegistrationBonus } from '@/components/PopupRegistrationBonus/PopupRegistrationBonus';
import { Slider } from '@/components/Slider/Slider';
import { desktopNavMenu, desktopNavMenuWithSport } from '@/constants';
import { aviatorNavMenuItem, buyBonusNavMenuItem } from '@/constants/navMenu/desktopNavMenu';
import { routes } from '@/constants/routes';
import { useIsGamePage, useIsMainPage, useMediaWidthDown, useSportUrlParams, useTypedSelector } from '@/hooks';
import { headerObject } from '@/utils/markerTree';

import type { NavMenuItem as NavMenuItemType } from '@/types';

import { AdditionalNav } from '../AdditionalNav/AdditionalNav';
import type { AdditionalNavClasses } from '../AdditionalNav/AdditionalNav.types';
import { LocaleButton } from '../LocaleButton/LocaleButton';
import { MenuButton } from '../MenuButton/MenuButton';
import { NavMenuItem } from '../NavMenuItem/NavMenuItem';
import { SignInButton } from '../SignInButton/SignInButton';

import { analytics } from './Navigation.analytics';
import { useAdditionalNav } from './Navigation.hooks';
import type { NavigationProps } from './Navigation.types';

import styles from './Navigation.module.scss';

const classes: AdditionalNavClasses = {
  navItem: styles.additionalLink,
};

const BaseNavigation: FC<NavigationProps> = ({ className }) => {
  const rootRef = useRef<HTMLDivElement | null>(null);

  const [registrationBonusShowed, setRegistrationBonusShowed] = useState(false);

  const router = useRouter();
  const { t } = useTranslation();
  const { createLinkProps } = useQueryPopupOpen();
  const bonusesCount = useBonusBalanceCount();
  const { main, app } = useLogos();
  const hasWheelCookie = useAccessCookie('wheel');
  const isGamePage = useIsGamePage();
  const isMainPage = useIsMainPage();

  const navRef = useRef<HTMLElement>(null);
  const shadowRef = useRef<HTMLDivElement>(null);

  const navMenuItems = process.env.SPORT_ACCESS ? desktopNavMenuWithSport : desktopNavMenu;

  const { visibleLinks, otherLinks } = useAdditionalNav({
    links: navMenuItems,
    containerRef: navRef,
    shadowRef,
    numberRemovedLinks: 2,
  });

  const { checkIsSportMenuActive } = useSportUrlParams();

  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const isErrorPage = useTypedSelector(selectIsErrorPage);

  const is850Screen = useMediaWidthDown('screen850');
  const is360Screen = useMediaWidthDown('screen360');
  const is1284Screen = useMediaWidthDown('screen1284');
  const isMdScreen = useMediaWidthDown('screenMd');

  const aviatorIndex = otherLinks.length === 0 ? visibleLinks.length - 1 : visibleLinks.length;
  const buyBonusIndex = otherLinks.length === 0 ? visibleLinks.length - 1 : visibleLinks.length;

  const links = useMemo(() => {
    if (isMdScreen) {
      return navMenuItems;
    }

    return visibleLinks;
  }, [isMdScreen, visibleLinks, navMenuItems]);

  const handleClickBonusButton = () => {
    if (!isAuth) {
      setRegistrationBonusShowed(true);
    } else {
      router.push(routes.bonuses);
    }
  };

  const handleCloseRegistrationBonus = useCallback(() => setRegistrationBonusShowed(false), []);

  const wheelProps = useMemo<Omit<ButtonAsLink, 'children' | 'as'>>(() => {
    if (isErrorPage) {
      return createLinkProps({ queryName: 'wheel', pathname: routes.home });
    }

    return createLinkProps({ queryName: 'wheel' });
  }, [isErrorPage, createLinkProps]);

  const inlinedLink = inlineQueries(router.pathname, router.query);

  const checkActiveLink = ({
    href,
    strictlyHighlightedOnUrl,
    exactMatch,
  }: Pick<NavMenuItemType, 'href' | 'strictlyHighlightedOnUrl' | 'exactMatch'>) => {
    if (router.pathname.includes(routes.sport.home)) {
      return checkIsSportMenuActive(href, strictlyHighlightedOnUrl);
    }

    return getLinkIsActive(inlinedLink, href, exactMatch);
  };

  const renderMenuItem = ({
    key,
    href,
    text,
    isAviator,
    exactMatch,
    strictlyHighlightedOnUrl,
    ...rest
  }: NavMenuItemType) => (
    <NavMenuItem
      {...(isAviator ? headerObject.navItem('aviator_button').nodeProps : headerObject.navItem(key).nodeProps)}
      key={key}
      isActive={checkActiveLink({ href, exactMatch, strictlyHighlightedOnUrl })}
      href={href}
      isAviator={isAviator}
      {...rest}
    >
      {t(key, text)}
    </NavMenuItem>
  );

  const renderMenuItems = (items: Array<NavMenuItemType>) => {
    return [
      ...items.slice(0, aviatorIndex).map(renderMenuItem),
      ...[aviatorNavMenuItem, buyBonusNavMenuItem].map(renderMenuItem),
      ...items.slice(buyBonusIndex).map(renderMenuItem),
    ];
  };

  return (
    <>
      <div
        ref={rootRef}
        className={cn(styles.root, className, {
          [styles.auth]: isAuth,
          [styles.game]: isGamePage,
          [styles.main]: isMainPage,
        })}
      >
        <NoSSR>
          <Button {...headerObject.logo.nodeProps} variant="text" className={styles.logo} as="link" href={routes.home}>
            <Image
              className={styles.logoImage}
              src={is850Screen ? app : main}
              priority
              fill
              style={{ objectFit: 'contain' }}
              alt="Banzai"
            />
          </Button>
        </NoSSR>

        <div className={styles.left}>
          {!is360Screen && (
            <Download variant="icon" testMarker={headerObject.downloadButton.android} analytics={analytics.download} />
          )}

          {!is850Screen && (
            <ColoredButton color="yellow" href={routes.promo.home} image="/assets/bonus/images/promoButtonIcon.webp">
              {is850Screen ? t('promo_bonuses', 'Promo and bonuses') : t('promo_bonuses_desktop', 'Promo')}
            </ColoredButton>
          )}

          {!is850Screen && (
            <NotificationWrapper count={bonusesCount}>
              <BonusButton className={styles.bonusButton} as="button" onClick={handleClickBonusButton} />
            </NotificationWrapper>
          )}

          <NoSSR>{hasWheelCookie && <ButtonLuckyWheel as="link" {...wheelProps} />}</NoSSR>
        </div>

        {isMdScreen && (
          <nav className={styles.navigation}>
            <Slider
              classes={{ slide: styles.navSlide }}
              breakpoints={{
                320: {
                  slidesPerView: 'auto',
                },
              }}
            >
              {renderMenuItems(navMenuItems)}
            </Slider>
          </nav>
        )}

        {!isMdScreen && (
          <nav className={styles.navigation} ref={navRef}>
            <div className={styles.shadowLinks} ref={shadowRef}>
              {renderMenuItems(navMenuItems)}
            </div>
            {renderMenuItems(links)}

            <AdditionalNav
              className={styles.additionalButton}
              classes={classes}
              offset={[32, 16]}
              links={otherLinks}
              placement="bottom-start"
            />
          </nav>
        )}

        <div {...headerObject.account.nodeProps} className={styles.right}>
          {!is1284Screen && <LocaleButton testMarker={headerObject.settings.lang} displayMode="grid" />}
          {isAuth && (
            <Balance
              {...headerObject.account.balance.nodeProps}
              className={styles.balance}
              withAmount={!(isGamePage && is850Screen)}
            />
          )}
          {!isAuth && <SignInButton />}
          <MenuButton {...headerObject.menuButton.nodeProps} shouldOpen className={styles.burger} />
        </div>
      </div>

      <PopupRegistrationBonus show={registrationBonusShowed} onClose={handleCloseRegistrationBonus} />
    </>
  );
};

export const Navigation = memo(BaseNavigation);
