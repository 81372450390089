import FlashlightIcon from '@public/assets/common/icons/flashlight.svg';
import GiftIcon from '@public/assets/common/icons/gift.svg';
import NotificationButtonIcon from '@public/assets/common/icons/notificationButton.svg';
import PromoCouponIcon from '@public/assets/common/icons/promoCoupon.svg';

import type { ColorsData } from './MobileMenuButton.types';

export const variants: ColorsData = {
  default: {
    MainIcon: PromoCouponIcon,
    LeftCornerIcon: null,
    counterBackground: 'red',
    withBorder: false,
  },
  bonus: {
    MainIcon: NotificationButtonIcon,
    LeftCornerIcon: null,
    counterBackground: 'yellow',
    withBorder: false,
  },
  fastBet: {
    MainIcon: PromoCouponIcon,
    LeftCornerIcon: FlashlightIcon,
    counterBackground: 'red',
    withBorder: true,
  },
  freeBet: {
    MainIcon: PromoCouponIcon,
    LeftCornerIcon: GiftIcon,
    counterBackground: 'red',
    withBorder: true,
  },
};
