import type { StaticImageData } from 'next/image';
import type { LevelRank } from '@mwl/core-lib';

import rankBronze from '@public/assets/sidebar/images/rankBronze.webp';
import rankGold from '@public/assets/sidebar/images/rankGold.webp';
import rankSilver from '@public/assets/sidebar/images/rankSilver.webp';

export const imageRankMap: Record<LevelRank, StaticImageData> = {
  BRONZE: rankBronze,
  SILVER: rankSilver,
  GOLD: rankGold,
};
