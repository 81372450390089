import type { FC } from 'react';
import { useMemo } from 'react';
import { extendAnalyticsData, useCasinoProviders } from '@mwl/core-lib';

import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import { Image } from '@/components/Image/Image';
import { Loader } from '@/components/Loader/Loader';

import type { ProviderListProps } from './ProviderList.types';
import { getButtonProps } from './ProviderList.utils';

import styles from './ProviderList.module.scss';

const maxCount = 12;

const ProviderList: FC<ProviderListProps> = ({ className, analytics }) => {
  const { providers, isLoaded, error } = useCasinoProviders();

  const displayProviders = useMemo(() => providers.filter(({ image }) => !!image), [providers]);

  const isLoading = !isLoaded && !error;

  return (
    <>
      {isLoading && (
        <div className={cn(styles.loader, className)}>
          <Loader />
        </div>
      )}

      {!isLoading && (
        <nav className={cn(styles.root, className)}>
          {displayProviders.slice(0, maxCount).map(({ id, image, title, productType }, index) => {
            const buttonProps = getButtonProps(title, productType);

            return (
              <ButtonIcon
                {...buttonProps}
                className={styles.provider}
                key={id}
                analytics={extendAnalyticsData(analytics, {
                  href: 'href' in buttonProps ? buttonProps.href : undefined,
                  img: image,
                  count: maxCount,
                  position: index + 1,
                })}
              >
                <Image
                  src={image}
                  loading="lazy"
                  width={150}
                  fill
                  style={{ objectFit: 'contain' }}
                  alt={`provider ${title}`}
                />
              </ButtonIcon>
            );
          })}
        </nav>
      )}
    </>
  );
};

export { ProviderList };
