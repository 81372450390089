import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useLogos } from '@mwl/core-lib';
import { NoSSR } from '@mwl/ui';

import AdultIcon from '@public/assets/common/icons/18plus.svg';

import { Button } from '@/components/Button/Button';
import { Download } from '@/components/Download/Download';
import { Image } from '@/components/Image/Image';
import { routes } from '@/constants';
import { useMediaWidthDown } from '@/hooks';
import { appPageObject } from '@/utils';

import { GCBValidator } from './components/GCBValidator/GCBValidator';
import { Navigation } from './components/Navigation/Navigation';
import { PaymentsList } from './components/PaymentsList/PaymentsList';
import { PoweredBy } from './components/PoweredBy/PoweredBy';
import { ProviderList } from './components/ProviderList/ProviderList';
import content from './content.json';
import { analytics } from './Footer.analytics';
import { useWithValidator } from './Footer.hooks';

import styles from './Footer.module.scss';

const Footer: FC = () => {
  const { t } = useTranslation();

  const { main, app } = useLogos();
  const withValidator = useWithValidator();
  const isXsScreen = useMediaWidthDown('screenXs');

  return (
    <footer {...appPageObject.footer.nodeProps} className={cn(styles.root)}>
      <ProviderList className={styles.providers} analytics={analytics.providers} />

      <Navigation className={styles.navigation} />

      <div className={styles.content}>
        <div className={styles.logo}>
          <NoSSR>
            <Button variant="text" as="link" href={routes.home}>
              <Image src={withValidator ? app : main} width={85} height={85} priority alt="Validator" />
            </Button>
          </NoSSR>
        </div>

        {withValidator && (
          <div className={styles.copyright}>
            {t('footer.information', content.information)} {t('footer.description', content.description)}
          </div>
        )}

        <div className={styles.additional}>
          {isXsScreen ? (
            <>
              <div className={cn(styles.additionalRow, styles.download)}>
                <Download
                  testMarker={appPageObject.footer.downloads('android_download')}
                  analytics={analytics.download}
                  classes={{ root: styles.download, button: styles.downloadButton }}
                />
              </div>
              <div className={styles.additionalRow}>
                <AdultIcon />
                <GCBValidator />
                {withValidator && <PoweredBy />}
              </div>
              <div className={styles.additionalRow}>
                <PaymentsList />
              </div>
            </>
          ) : (
            <>
              <div className={styles.additionalRow}>
                <Download
                  testMarker={appPageObject.footer.downloads('android_download')}
                  analytics={analytics.download}
                />
                {withValidator && <PoweredBy />}
              </div>
              <div className={styles.additionalRow}>
                <PaymentsList className={styles.payments} />
                <AdultIcon />
                <GCBValidator />
              </div>
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

export * from './Footer.types';
export { Footer };
