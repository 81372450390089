import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { selectCouponSelectedBetsV2, selectIsCouponQuickBetsV2 } from '@mwl/core-lib';

import { useAccountSelectItems, useTypedSelector } from '@/hooks';
import { selectCouponPreviewState, setShowPreviewCoupon } from '@/store';

import type { MobileMenuButtonVariants } from './components/MobileMenuButton/MobileMenuButton.types';

export const useMobileMenuButton = () => {
  const dispatch = useDispatch();
  const isShowPreviewCoupon = useTypedSelector(selectCouponPreviewState);
  const isQuickBets = useTypedSelector(selectIsCouponQuickBetsV2);
  const selectedBets = useTypedSelector(selectCouponSelectedBetsV2);
  const { hasBonus, hasFreebet } = useAccountSelectItems();

  const handleShowCouponPreview = useCallback(() => {
    dispatch(setShowPreviewCoupon(!isShowPreviewCoupon));
  }, [dispatch, isShowPreviewCoupon]);

  const mobileButtonVariant: MobileMenuButtonVariants = useMemo(() => {
    if (isQuickBets) {
      return 'fastBet';
    }

    if (hasFreebet) {
      return 'freeBet';
    }

    if (hasBonus) {
      return 'bonus';
    }

    return 'default';
  }, [hasBonus, hasFreebet, isQuickBets]);

  return { selectedBets, mobileButtonVariant, handleShowCouponPreview, isShowPreviewCoupon };
};
