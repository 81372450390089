import type { FC } from 'react';

import { Button } from '@/components/Button/Button';
import { Image } from '@/components/Image/Image';

import { useWithGCBValidator } from '../../Footer.hooks';

import type { GCBValidatorProps } from './GCBValidator.types';

import styles from './GCBValidator.module.scss';

const GCBValidator: FC<GCBValidatorProps> = ({ className }) => {
  const withGCBValidator = useWithGCBValidator();

  if (!withGCBValidator) {
    return undefined;
  }

  return (
    <Button
      as="external"
      href={withGCBValidator.href}
      variant="text"
      target="_blank"
      className={cn(styles.root, className)}
    >
      <Image
        src={withGCBValidator.imageSrc}
        alt={withGCBValidator.imageAlt}
        width={42}
        height={24}
        className={styles.icon}
      />
    </Button>
  );
};

export { GCBValidator };
