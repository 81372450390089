import type { FC } from 'react';
import { useRouter } from 'next/router';

import { routes } from '@/constants';

import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { Meta } from '../Meta/Meta';
import { MobileMenu } from '../MobileMenu/MobileMenu';
import { Sidebar } from '../Sidebar/Sidebar';

import type { LayoutProps } from './Layout.types';

import styles from './Layout.module.scss';

const Layout: FC<LayoutProps> = ({
  children,
  title,
  description,
  coverContent,
  withBackground,
  gameMode,
  withHeader = true,
  withFooter = true,
  sticky,
}) => {
  const router = useRouter();
  const isInWebView = router.query.inWebView === '1';
  const isSportPage = router.pathname.startsWith(routes.sport.home);

  return (
    <div
      className={cn(styles.root, {
        [styles.cover]: coverContent,
        [styles.background]: withBackground,
        [styles.webView]: isInWebView,
        [styles.sticky]: sticky,
      })}
    >
      <Meta title={title} description={description} />
      <Sidebar />

      {withHeader && !isInWebView && <Header />}
      <main className={styles.content}>{children}</main>
      {withHeader && !gameMode && !isInWebView && <MobileMenu isSportPage={isSportPage} />}
      {withFooter && !isInWebView && <Footer />}
    </div>
  );
};

export { Layout };
